import React from 'react';
import './App.css';
import  QRCode  from 'qrcode.react'

const App: React.FC = () => {
  return (
    <div className="App">
      <div className="App-container">
        <div className ="top-element">
            JOKES FOR EVERYONE !!
        </div>

        <div className = "principal-container">
          <div className="left-side">
           <QRCode className="qr-lg" size="250" value={process.env.REACT_APP_API_URL} />
           <QRCode className="qr-sm" size="140" value={process.env.REACT_APP_API_URL} />
          
          </div>
          <div className="right-side">
            <h1>Our mission</h1>
            <p>Keep up to date with the latest jokes of the moment. Remember that life without laughter is not life</p>
            </div>
        </div>
      </div>
    </div>
  );
}

export default App;
